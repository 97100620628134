/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Desi Karunia Ssri
==============================================================================================*/

@import "sass-lib.scss";

@media (max-width: 767px) {
	.mid-content {
		&.no-banner {
			padding-top: 150px;
			.middle {
				.content-profile {
					display: block;
					.content-left {
						float: none;
						width: 100%;
						padding-right: 0;
						display: block;
					}
					.content-right {
						float: none;
						width: 100%;
						display: block;
					}
				}
			}
		}
	}
	.highcharts-container {
		width: 100%;
	}
	.highcharts-figure,
	.highcharts-data-table table {
		min-width: unset;
	}
	.wrap-report {
		margin-top: -80px;
	}
	.about-us .content {
		margin: 0 !important;
		width: 100% !important;
		// .img-content {
		//     border: none !important;
		//     float: none;
		//     left: 0;
		//     right: 0;
		//     margin: 0;
		//     padding: 40px 0 0 !important;
		//     position: relative;
		//     text-align: center;
		//     &::before {
		//         background: #0083c3;
		//         content: '';
		//         height: 60%;
		//         left: 0;
		//         position: absolute;
		//         right: 0;
		//         top: 0;
		//         z-index: -1;
		//     }
		//     img {
		//         position: unset !important;
		//         left: 0 !important;
		//         margin: 0 auto !important;
		//         width: 85% !important;
		//     }
		// }
		.desc-content {
			.highlight-text {
				width: 100% !important;
			}
			.description {
				width: 100% !important;
			}
		}
	}
	.mid-content .middle .box-content p {
		width: 100% !important;
	}
	.homepage .main-slider .list .slider-img {
		object-fit: cover;
		object-position: center;
		height: 400px !important;
	}
	header .panel {
		width: 78%;
	}
	header .wrapper-large {
		.burger-slide {
			top: 50% !important;
			right: 30px !important;
			left: unset !important;
			transform: translateY(-50%);
			&.show {
				top: 40px !important;
			}
		}
		ul.menu {
			margin: 0;
			width: 100%;
			height: calc(100% - 150px);
			&::-webkit-scrollbar {
				width: 4px;
			}
			li {
				display: block;
				margin-bottom: 20px !important;
				a {
					margin: 0;
					padding: 10px 0;
				}
				> .sub-dropdown {
					ul {
						li {
							margin-bottom: 0 !important;
						}
					}
				}
				&.announcement-menu {
					display: block;
				}
				&.search-btn {
					display: block;
					box-sizing: border-box;
					padding: 0 30px 0 23px;
					margin-bottom: 0 !important;
					width: 78%;
					bottom: 90px;
				}
				&.lang {
					display: block;
					box-sizing: border-box;
					border-bottom: none !important;
					border-top: none !important;
					margin: 0 !important;
					padding: 10px 30px 0 30px !important;
					width: 78% !important;
					bottom: 40px;
					a {
						&:last-child {
							margin-left: 10px !important;
						}
					}
				}
			}
		}
	}
	header.active .wrapper-large .logo {
		margin-bottom: 15px !important;
	}
	header.active .wrapper-large ul.menu li.search-btn {
		width: 78%;
		padding: 0 30px 0 23px;
	}
	.mid-content.wrapper-small .middle .img-responsive {
		overflow-x: auto;
		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
			position: relative;
			top: -5px;
		}
		/* Track */
		&::-webkit-scrollbar-track {
			background: #e6e6e6;
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #a8a8a8;
			border-radius: 10px;
		}
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #727272;
		}
		img {
			width: max-content;
		}
	}
	.mid-content.wrapper-small .middle img {
		height: auto;
		width: 100%;
	}
	.menu-id {
		&.float {
			position: relative;
			top: 0 !important;
			background: #fff;
		}
		.menu-target {
			&.scrollSlide {
				padding: 20px 30px;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: 740px) {
	.cmap {
		height: 200px;
	}
	.modal {
		.modal-content {
			top: 74px;
		}
		video {
			height: 300px;
		}
	}
	.video-project .video-slider .item .desc-project .description .btn-white {
		margin-right: 5px;
	}
	.box-video .video-project .item .desc-project .description {
		margin-top: 35px;
	}
	.box-video .slick-arrow.nav-left {
		bottom: 230px;
	}
	.box-video .slick-arrow.nav-right {
		bottom: 230px;
	}
	.video-project .video-slider .item .desc-project-detail .numb {
		width: 500px;
	}
	.news-slide .news .name {
		margin-bottom: 0;
	}
	.homepage .main-slider .slick-dots li button {
		left: 550px;
	}
	.box-profile .box {
		width: 100%;
	}
	.mid-content .middle .list-profile .profile {
		width: 266px;
	}
	.mid-content.news-sec .wrapper-medium .wrapper-medium .news-slide .news {
		width: 44vw;
	}
	.box-video .video-project .item .desc-project .description {
		min-height: 130px;
	}
	.mid-content.news-list .middle .news {
		display: inline;
		float: right;
	}
	#ytplayer {
		height: 289px;
		width: 169%;
		position: relative;
		left: 0%;
	}
	/*.modal .close{ right: -250px }*/
	.box-video .video-project .video-slider iframe {
		width: 100%;
		height: 418px;
	}

	.investing {
		border: none;
		padding: 0;
		position: relative;
		top: 0;
		margin-top: 0;
		overflow: hidden;
		&:before {
			display: none;
		}
		.content {
			z-index: 2;
			border-bottom: 1px solid #ccc;
			.img-content {
				border-left: 175px solid #0083c3;
				position: relative;
				padding: 40px 0;
				width: 100%;
				img {
					position: relative;
					left: -13%;
					width: 70%;
				}
			}
			.desc-content {
				padding-top: 50px;
				width: auto;
				padding-bottom: 20px;
				margin: 0 20px;
				.logo-content {
					width: 65px;
				}
				.text-grey {
					font-size: 16px;
				}
				.highlight-text {
					font-size: 19px;
					width: 100%;
				}
				.description {
					width: 100%;
				}
				&:after {
					content: "";
					width: 480px;
					height: 1px;
					background: #000;
					display: block;
					left: 0;
					right: 0;
					bottom: -5px;
					border-radius: 100%;
					position: absolute;
					margin: auto;
					z-index: 5;
					@include transition(height ease-out 0.4s);
				}
			}
		}
		&.aksen {
			background: none;
			width: auto;
		}
	}
}
@media (min-width: 0px) and (max-width: 640px) {
	.investing {
		.content {
			.img-content {
				img {
					left: -17%;
					width: 70%;
				}
			}
		}
	}
	footer {
		height: 60px;
		.menu-footer {
			ul {
				display: none;
			}
			p {
				font-size: 12px;
				float: none;
				margin: 0;
				text-align: center;
			}
		}
	}
	.menu-id {
		ul {
			padding: 20px 30px;
			li {
				&.active-section {
					background: url(../images/material/down-active.png) no-repeat 95% 12px;
				}
			}
		}
		/*.img-selmob{ position: absolute;top: 55px; right: 50px; }*/
	}
	// .menu-id .menu-target.scrollSlide {
	// 	background: #fff;
	// 	border-bottom: 1px solid rgba(0,0,0, .2);
	// 	li {
	// 		width: auto;
	// 		border: none;
	// 		padding: 0 10px;
	// 		float: none;
	// 		background: none;
	// 		&:not(.active){
	// 			display: inline-block;
	// 		}
	// 		&.active {
	// 			display: none;
	// 		}
	// 	}
	// }
	.modal {
		.modal-content {
			top: 68px;
		}
		video {
			width: 71%;
			height: 272px;
		}
		/*.close{ right: -150px; top: 0; }*/
	}
	.box-video .video-project .item .desc-project .description {
		margin-top: 15px;
		float: right;
		width: 50%;
	}
	.video-project .video-slider .item .desc-project .description .btn-white {
		display: block;
		margin-top: 10px;
	}
	.box-video .slick-arrow.nav-left {
		bottom: 250px;
	}
	.box-video .slick-arrow.nav-right {
		bottom: 250px;
	}
	.box-video .video-project .video-slider iframe {
		height: 85%;
	}
	.homepage .main-slider .slick-dots li button {
		left: 500px;
	}
	.mid-content .middle .list-profile .profile {
		width: 250px;
	}
	.mid-content.news-sec .wrapper-medium .wrapper-medium .news-slide .news {
		width: 43vw;
	}
	.mid-content .middle .list-profile .profile-slider .profile .images img {
		width: 100%;
		max-height: unset;
	}
	#ytplayer {
		height: 282px;
		width: 167%;
		position: relative;
		left: -29%;
	}
	.box-video .video-project .video-slider iframe {
		width: 100%;
		height: 361px;
	}
	.box-video .video-project .item .thumb {
		height: 50%;
	}
	.box-video .video-project {
		height: 548px;
	}
	.box-video .video-project .video-slider .item .cover-thumb {
		top: 20%;
	}

	.row-flex {
		.col {
			&.col-50 {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
	.highcharts-container {
		width: 80%;
	}
	.wrap-table {
		overflow-x: scroll;
		.table-list {
			thead {
				th {
					font-size: 0.75rem;
					padding: 20px 10px;
					&:first-child {
						color: #333333;
						font-weight: normal;
					}
					p {
						font-size: 0.75rem;
						margin: 0;
					}
				}
			}
			tbody {
				td {
					font-size: 0.75rem;
					padding: 20px 10px;
					line-height: 1.3;
				}
			}
		}
	}
	.wrap-report {
		flex-direction: column;
		.side-img {
			flex: unset;
			max-width: unset;
			width: 100%;
			margin-bottom: 40px;
			text-align: center;
		}
		.side-text {
			flex: unset;
			max-width: unset;
			width: 100%;
		}
	}
	.menu-id {
		ul {
			display: block;
			overflow: unset;
			margin: 0;
			&.scroll {
				overflow: unset;
			}
			li {
				max-width: 100%;
			}
		}
	}
	.higlight-download {
		flex-flow: row wrap;
		figure,
		.text {
			flex: unset;
			max-width: 100%;
		}
		figure {
			width: 100%;
			img {
				width: 100%;
			}
		}
		.text {
			padding: 17px 15px;
			flex-flow: column wrap;
			width: 100%;
			align-items: flex-start;
			h5 {
				padding-left: 0;
				margin: 5px 0px;
			}
			.link-download {
				margin-left: 0;
			}
		}
	}
	.listing-download {
		.list {
			flex-flow: column wrap;
			align-items: flex-start;
		}
		.link-download {
			margin-left: 0;
		}
		h5 {
			padding-left: 0;
			margin: 5px 0 10px !important;
			display: block;
			max-width: 100%;
		}
		&.gms {
			p {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
	.listing-report {
		flex-flow: row wrap;
		margin: 40px 0px;
		.list {
			flex: unset;
			max-width: 100%;
			margin-bottom: 40px;
			padding: 0;
			width: 100%;
		}
	}
	.wrap-statement {
		flex-flow: row wrap;
		.text,
		figure {
			flex: unset;
			max-width: 100%;
			width: 100%;
		}
		.text {
			order: 2;
			padding: 25px;
		}
		figure {
			order: 1;
		}
		.slider-download {
			max-height: 100%;
			overflow: visible;
		}
	}
	.filter {
		flex-flow: row wrap;
		label,
		.slider-filter {
			flex: 0 0 100%;
			max-width: 100%;
		}
		label {
			margin-bottom: 25px;
		}
		.slider-filter {
			.list {
				p {
					padding: 0;
				}
			}
			.slick-arrow {
				width: 6px;
				height: 12px;
				object-fit: cover;
				&.slick-prev {
					right: calc(100% + 5px);
				}
				&.slick-next {
					left: calc(100% + 5px);
				}
			}
		}
	}
	.wrap-gms {
		padding: 25px;
	}
}
@media (min-width: 0px) and (max-width: 570px) {
	.homepage .main-slider .slick-dots li button {
		left: 445px;
	}
	.news-list {
		.middle {
			.info {
				float: none;
				width: 100%;
				height: 150px;
				h2 {
					font-size: 24px;
					line-height: 0px;
					width: 100%;
				}
			}
			.news {
				.news-list {
					width: 100vw;
					h3 {
						font-size: 23px;
						width: 100%;
					}
					p {
						width: 100%;
					}
				}
			}
		}
	}
	#ytplayer {
		height: 269px;
		width: 159%;
		position: relative;
		left: -36%;
	}
	/*.modal .close{ right: -50px; }*/
	.box-video .video-project .video-slider iframe {
		width: 100%;
		height: 321px;
	}
	.box-video.details .video-project .vid_web {
		height: 321px;
	}
}
@media (min-width: 0px) and (max-width: 480px) {
	section {
		overflow: hidden;
		width: auto;
	}
	header {
		overflow: hidden;
	}
	header .wrapper-large ul.menu li.lang,
	header.active .wrapper-large ul.menu li.lang {
		border-bottom: 1px solid #fff;
		padding: 20px 0;
		box-sizing: border-box;
		margin-left: 20px;
		width: 70%;
		border-top: 1px solid #fff;
		a {
			padding: 0;
			margin-right: 10px;
		}
	}
	.wrapper-large {
		width: 400px;
		margin: 0 auto;
	}
	.landing {
		.box {
			.index-img {
				height: 210px;
				.desc-landing {
					padding: 0;
					padding-top: 21px;
					padding-left: 15px;
					p {
						display: none;
					}
					.text-direct {
						display: block;
						font-size: 11px;
					}
					.title {
						margin: 0px;
						font-size: 23px;
					}
					& > img {
						width: 30px;
					}
				}
				.img-landing {
					height: 480px;
				}
			}
		}
	}
	.mid-content#csr {
		margin-bottom: 0;
		padding-bottom: 0;
		padding-top: 60px;
	}
	.csr-slider {
		margin: 0 30px;
		margin-bottom: 60px;
		.slick-dots {
			li {
				button {
					bottom: 50px;
					left: 260px;
				}
			}
		}
	}
	.latest-news {
		.box-latest-news {
			.box-news-title {
				h2 {
					font-size: 23px;
				}
				.subtitle {
					font-size: 16px;
				}
			}
			.box-news {
				float: none;
				width: auto;
				padding: 0px;
				min-height: 150px;
				border-bottom: 1px solid #ccc;
				margin: 20px;
				.box-title {
					font-size: 23px;
				}
				.box-desc {
					display: none;
				}
				.box-title {
					min-height: unset;
					margin-bottom: 10px;
				}
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
	header {
		.wrapper-large {
			.logo {
				img {
					width: 67px;
					&.white {
						display: none;
					}
					&.active {
						display: none;
					}
					&.mob.white {
						display: block;
					}
				}
			}
			.burger-slide {
				left: 80%;
			}
			ul.menu li.search-btn input[type="search"]:focus,
			ul.menu li.search-btn input[type="search"] {
				right: -3px;
				background-color: transparent;
				background: url(../images/material/search-mob.png) no-repeat 4px center;
				box-shadow: none;
				width: 45vw;
				border: none;
				background-size: 15%;
				padding-left: 45px;
			}
		}
		&.active {
			.wrapper-large {
				.logo {
					img {
						width: 67px;
						&.white {
							display: none;
						}
						&.active {
							display: none;
						}
						&.mob.white {
							display: none;
						}
						&.mob.active {
							display: block;
						}
					}
				}
				.burger-slide {
					left: 80%;
				}
				.panel {
					top: 76px;
				}
				ul.menu li.search-btn input[type="search"]:focus,
				ul.menu li.search-btn input[type="search"] {
					right: -3px;
					background-color: transparent;
					background: url(../images/material/search-mob.png) no-repeat 4px
						center;
					box-shadow: none;
					width: 45vw;
					border: none;
					background-size: 15%;
					padding-left: 45px;
				}
			}
		}
		.panel {
			top: 77px;
		}
	}
	.homepage {
		.main-slider {
			.list {
				height: auto;
				.slider-img {
					height: auto;
					width: 100%;
				}
			}
			.content {
				h1 {
					font-size: 23px;
					line-height: 30px;
				}
				p {
					display: none;
					font-size: 16px;
				}
				.text-direct {
					display: block;
				}
				&.desc-center p {
					display: block;
				}
			}
			.slick-dots {
				li {
					button {
						left: 30px;
						top: -31px;
					}
				}
			}
		}
	}
	.about-us {
		border: none;
		padding: 0;
		position: relative;
		top: -8px;
		margin-top: 0;
		overflow: hidden;
		&:before {
			display: none;
		}
		.content {
			z-index: 2;
			width: 415px;
			border-bottom: 1px solid #ccc;
			margin-left: -10px;
			.img-content {
				border-left: 175px solid #0083c3;
				position: relative;
				padding: 20px 0;
				width: 100%;
				img {
					position: relative;
					left: -33%;
					width: 80%;
				}
			}
			.desc-content {
				padding-top: 50px;
				width: auto;
				padding-bottom: 20px;
				margin: 0 20px;
				.logo-content {
					width: 65px;
				}
				.text-grey {
					font-size: 16px;
				}
				.highlight-text {
					font-size: 19px;
					width: 100%;
				}
				.description {
					width: 100%;
				}
				&:after {
					content: "";
					width: 480px;
					height: 1px;
					background: #000;
					display: block;
					left: 0;
					right: 0;
					bottom: -5px;
					border-radius: 100%;
					position: absolute;
					margin: auto;
					z-index: 5;
					@include transition(height ease-out 0.4s);
				}
			}
		}
		&.aksen {
			background: none;
			width: auto;
		}
	}
	.investing {
		border: none;
		padding: 0;
		position: relative;
		top: 0;
		margin-top: 0;
		overflow: hidden;
		&:before {
			display: none;
		}
		.content {
			z-index: 2;
			border-bottom: 1px solid #ccc;
			.img-content {
				border-left: 175px solid #0083c3;
				position: relative;
				padding: 40px 0;
				width: 100%;
				img {
					position: relative;
					left: -33%;
					width: 80%;
				}
			}
			.desc-content {
				padding-top: 50px;
				width: auto;
				padding-bottom: 20px;
				margin: 0 20px;
				.logo-content {
					width: 65px;
				}
				.text-grey {
					font-size: 16px;
				}
				.highlight-text {
					font-size: 19px;
					width: 100%;
				}
				.description {
					width: 100%;
				}
				&:after {
					content: "";
					width: 480px;
					height: 1px;
					background: #000;
					display: block;
					left: 0;
					right: 0;
					bottom: -5px;
					border-radius: 100%;
					position: absolute;
					margin: auto;
					z-index: 5;
					@include transition(height ease-out 0.4s);
				}
			}
		}
		&.aksen {
			background: none;
			width: auto;
		}
	}
	.text-direct {
		font-size: 16px;
	}
	.mid-content {
		.middle {
			.box-content {
				float: none;
				width: 100%;
				p {
					padding: 0;
				}
				.text-direct {
					margin-left: 0;
				}
			}
			.box-detail {
				.detail-project {
					width: 100%;
				}
			}
			.news {
				padding-top: 0;
			}
			.news-title {
				margin-top: 50px;
				margin-bottom: 20px;
			}
		}
		&.box-project-sec {
			.middle {
				.box-project {
					.image-project {
						width: 100%;
						margin: 10px auto;
						display: block;
					}
				}
			}
		}
	}
	.box-video {
		width: 480px;
		.video-project {
			.video-slider {
				.item {
					.cover-thumb {
						text-align: center;
						left: 0;
						right: 0;
						top: 15%;
						img {
							width: 70px;
							margin-top: -30px;
						}
						p {
							font-size: 13px;
							margin: 0 !important;
						}
					}
					.desc-project {
						left: 0;
						width: 100vw;
						bottom: -35px;
					}
				}
			}
			.item {
				.desc-project {
					height: auto;
					.numb {
						font-size: 19px;
					}
					.info-text {
						float: none;
						width: auto;
						h2 {
							font-size: 19px;
						}
					}
					.description {
						.btn-white {
							display: block;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		&.details {
			.slick-arrow {
				&.nav-left {
					right: 60px;
					bottom: 42px;
				}
				&.nav-right {
					right: 30px;
					bottom: 41px;
				}
			}
		}
		.slick-arrow.nav-right {
			bottom: 300px;
			right: 50px;
		}
		.slick-arrow.nav-left {
			bottom: 300px;
			right: 120px;
		}
	}
	.news-list {
		.middle {
			.info {
				float: none;
				width: 100%;
				height: 150px;
				h2 {
					font-size: 24px;
					line-height: 0px;
					width: 100%;
				}
			}
			.news {
				.news-list {
					width: 100%;
					h3 {
						font-size: 23px;
					}
				}
			}
		}
	}
	.news-slide {
		margin-top: 50px;
		.news {
			.images {
				overflow: hidden;
				width: 350px;
			}
			.name {
				width: 80vw;
				margin-bottom: 0;
			}
			.position {
				width: 350px;
			}
		}
	}
	.static-pages {
		.main-banner {
			img {
				width: 100%;
				margin-top: 0 !important;
				height: auto;
			}
		}
	}
	.mid-content {
		.wrapper-small {
			width: 100vw;
		}
		&.wrapper-small {
			.middle {
				width: auto;
				margin: 30px;
			}
		}
		&.visionmission {
			.content-left,
			.content-right {
				float: none;
			}
			.content-right {
				margin-left: 0;
				margin-top: 30px;
			}
		}
		&.history {
			padding: 30px 0;
			.content-left,
			.content-right {
				float: none;
			}
			.content-left {
				width: 62%;
				.image-cover {
					max-height: unset;
					img {
						width: 100%;
						margin-bottom: 0;
					}
				}
			}
			.middle {
				.content-right {
					width: auto;
					padding: 30px;
					padding-bottom: 0;
				}
			}
			.history-nav {
				margin-top: 0;
				.item {
					&.slick-active p {
						opacity: 0;
					}
					&.slick-current p {
						opacity: 1;
					}
					&.slick-center {
						.history-year {
							margin: 0;
							margin-bottom: 12px;
						}
						p {
							margin: 0;
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		.middle {
			.content-left,
			.content-right {
				float: none;
				width: auto;
			}
			.list-profile {
				margin-top: 50px;
				&.fir-list {
					margin-top: 30px;
				}
				.profile-slider {
					width: 100%;
					margin-left: 0;
					position: relative;
					.slick-arrow {
						border: none;
						background: none;
						position: absolute;
						top: 50%;
						font-size: 0;
						width: 40px;
						height: 30px;
						display: block;
						z-index: 3;
						&.slick-next {
							right: 0;
							background: url(../images/material/left-active.png) no-repeat;
							background-position: right;
							&.slick-disabled {
								background: url(../images/material/right-disabled.png) no-repeat;
							}
						}
						&.slick-prev {
							left: 0;
							background: url(../images/material/right-active.png) no-repeat;
							&.slick-disabled {
								background: url(../images/material/left-disabled.png) no-repeat;
							}
						}
					}
					.profile {
						margin-right: 0;
						margin: 0 30px;
						.name {
							min-height: unset;
							font-size: 18px;
						}
						.images,
						.position,
						.name {
							max-width: 80%;
							margin: auto;
							margin-bottom: 10px;
						}
						a {
							margin-left: 10%;
							font-size: 15px;
						}
					}
					.nav-right {
						right: 0px;
					}
				}
			}
			p {
				font-size: 16px;
			}
			.content-left .content {
				width: auto;
			}
			.content-right {
				.description-text {
					width: 100%;
				}
			}
		}
		&.detail-profile {
			.wrapper-medium {
				.content-left {
					margin: 0 auto;
					float: none;
				}
				.content-right {
					margin-top: 30px;
					width: auto;
					float: none h5 {
						font-size: 16px;
					}
					h2 {
						font-size: 19px;
					}
					.description {
						font-size: 16px;
						line-height: 25px;
					}
				}
			}
		}
		&.box-project-sec {
			.middle {
				.box-project {
					.image-project {
						max-height: unset;
						img {
							width: 100%;
						}
					}
				}
			}
		}
		&#management {
			.wrapper-medium {
				.highlight-text {
					font-size: 24px;
				}
			}
		}
		&.news-sec {
			.middle {
				.wrapper-small {
					width: auto;
				}
			}
			.wrapper-medium {
				.news-slide {
					.slick-slide {
						float: none;
					}
					.news {
						width: auto;
					}
				}
			}
		}
		.pb-60 {
			padding-bottom: 0;
		}
	}
	.mid-content.box-project-sec {
		padding-bottom: 0;
	}
	.box-profile {
		.box {
			width: 100vw;
			display: block;
			.title {
				font-size: 16px;
			}
			.profile-name {
				font-size: 23px;
			}
		}
	}
	.image-grid {
		width: 400px;
	}
	.box-contact {
		.wrapper-small {
			width: 90vw;
			padding: 0 20px;
			@include boxSizing(border-box);
		}
		.wrapper-medium {
			.fl {
				width: 100%;
			}
			.form {
				.field.fr {
					margin-top: 0;
					float: none;
					border: none;
					margin-top: 20px;
				}
				.field {
					display: flex;
					input[type="text"],
					input[type="email"],
					textarea {
						width: 44vw;
						font-size: 16px;
					}
					input[type="submit"] {
						width: 100%;
						background-position: 220px;
					}
					label {
						padding: 20px 10px;
					}
				}
			}
		}
	}
	.g-recaptcha {
		transform: scale(0.85);
		-webkit-transform: scale(0.85);
		transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
	}
	.modal .modal-content {
		top: 15%;
	}
	.modal {
		.modal-content {
			width: 90%;
			left: 0;
		}
		.close {
			right: 15px;
		}
		iframe,
		video {
			width: 100%;
			height: 240px;
		}
	}
	.box-video .video-project .item .desc-project .description {
		height: auto;
		min-height: auto;
		width: 100%;
	}
	.video-project .video-slider .item .desc-project-detail .numb {
		width: 300px;
		font-size: 13px;
		margin-bottom: -2px;
	}
	.mid-content.wrapper-small .middle .box-detail .detail-project {
		width: 100%;
	}
	.mid-content.news-list .middle .news {
		width: 100%;
	}
	.box-video.details {
		margin: 0;
		margin: auto;
		.video-project {
			&.detail-project {
				margin: 0;
				height: auto;
			}
			.video-slider {
				.item {
					height: auto;
					overflow: hidden;
					border-radius: 5px;
					.thumb {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 1;
						object-position: center;
						height: 270px;
					}
					.cover-thumb {
						z-index: 2;
						top: 35%;
					}
					.desc-project-detail {
						bottom: 12px;
						display: block;
						z-index: 2;
						padding: 7px;
					}
				}
			}
			.vid_web {
				height: 270px;
				video {
					height: 270px;
				}
			}
		}
	}
	#ytplayer {
		height: 219px;
		width: 148%;
		position: relative;
		left: -55%;
	}
	.box-video .video-project .video-slider iframe {
		width: 100%;
		height: 268px;
	}
	.box-video.details .video-project .vid_web iframe {
		width: 100%;
		height: 269px;
	}
	footer {
		height: auto;
		min-height: 60px;
		flex-wrap: wrap;
		padding: 30px 30px 50px;
		.info {
			margin-bottom: 30px;
			width: 100%;
			.address {
				p {
					margin: 0 0 10px;
				}
			}
		}
		.menu-footer {
			padding: 0;
			width: 100%;
			ul {
				display: block;
				text-align: left;
				li {
					padding-left: 0;
					width: 100%;
				}
			}
			p {
				font-size: 12px;
				float: none;
				margin: 0;
				text-align: left;
			}
		}
	}
	.search-result {
		margin-top: 50px;
		.wrapper-small {
			width: 100vw;
		}
		input[type="search"] {
			/*width: 80vw; margin-left: 30px;*/
			margin-top: 0px;
		}
	}
	.searchmenu {
		display: none;
	}
	#ytplayer {
		height: 226px;
		width: 134%;
		position: relative;
		left: 4%;
		top: 50px;
	}
	.modal .close {
		/*right: -114px; */
		font-size: 40px;
		top: 10px;
	}
	.homepage-project:before {
		content: "";
		width: 85%;
		height: 1px;
		background: #ccc;
		display: block;
		left: 0;
		right: 0;
		top: 0;
		border-radius: 100%;
		position: absolute;
		margin: auto;
	}

	.highcharts-container {
		width: 100%;
	}
	.wrap-report {
		margin-top: 0;
		padding: 50px 30px;
	}
	.wrap-document {
		padding: 50px 0 0;
	}
	.table-doc {
		tr {
			td {
				font-size: 0.875rem;
				padding: 14px 10px;
				line-height: 1.2;
				&:first-child {
					font-size: 0.875rem;
				}
				&:last-child {
					a {
						padding-left: 0;
						font-size: 0.75rem;
						&:before {
							background: none;
						}
					}
				}
			}
		}
	}
	.pagging {
		ul {
			li {
				margin: 0 6px;
				font-size: 0.875rem;
			}
		}
	}
}
@media (min-width: 0px) and (max-width: 430px) {
	.landing .box .index-img {
		height: 185px;
	}
}
@media (min-width: 0px) and (max-width: 380px) {
	.box-video .video-project .video-slider iframe {
		width: 100%;
		height: 183px;
	}
	.box-video.details .video-project .vid_web iframe {
		width: 100%;
		height: 183px;
	}
	.landing .box .index-img {
		height: 140px;
	}
	.wrapper-large,
	.wrapper-medium,
	.wrapper-small {
		width: auto;
		overflow: hidden;
	}
	.about-us {
		border: none;
		padding: 0;
		position: relative;
		top: -8px;
		margin-top: 0;
		overflow: hidden;
		&:before {
			display: none;
		}
		.content {
			z-index: 2;
			width: 415px;
			border-bottom: 1px solid #ccc;
			margin-left: -10px;
			.img-content {
				border-left: 175px solid #0083c3;
				position: relative;
				padding: 20px 0;
				width: 100%;
				img {
					position: relative;
					left: -33%;
					width: 60%;
				}
			}
			.desc-content {
				padding: 0 20px;
				padding-top: 50px;
				width: auto;
				padding-bottom: 20px;
				box-sizing: border-box;
				.highlight-text {
					width: 80%;
				}
				.description {
					width: 80%;
				}
			}
		}
	}
	.investing {
		border: none;
		padding: 0;
		position: relative;
		top: -8px;
		margin-top: 0;
		overflow: hidden;
		&:before {
			display: none;
		}
		.content {
			z-index: 2;
			border-bottom: 1px solid #ccc;
			.img-content {
				border-left: 175px solid #0083c3;
				position: relative;
				padding: 20px 0;
				width: 100%;
				img {
					position: relative;
					left: -33%;
					width: 60%;
				}
			}
			.desc-content {
				padding: 0 10px;
				padding-top: 15px;
				width: auto;
				padding-bottom: 20px;
				box-sizing: border-box;
			}
		}
	}
	.box-video .slick-arrow.nav-left {
		bottom: 300px;
	}
	.box-video .slick-arrow.nav-right {
		bottom: 300px;
	}
	.video-project .video-slider .item .desc-project-detail .numb {
		width: 211px;
		font-size: 13px;
	}
	.news-slide .news .images {
		width: 300px;
	}
	.box-video.details {
		margin: 0;
		margin: auto;
		.video-project {
			&.detail-project {
				margin: 0;
				height: auto;
			}
			.video-slider {
				.item {
					height: auto;
					overflow: hidden;
					border-radius: 5px;
					.thumb {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 1;
						object-position: center;
						height: 211px;
					}
					.cover-thumb {
						z-index: 2;
						top: 20%;
					}
					.desc-project-detail {
						bottom: 9px;
						display: block;
						z-index: 2;
					}
				}
			}
			.vid_web {
				height: 211px;
				video {
					height: 211px;
				}
			}
		}
	}
	.mid-content.wrapper-small {
		padding: 0;
	}
	#ytplayer {
		height: 162px;
		width: 96%;
		position: relative;
		left: 5%;
	}
	/*.modal .close{ right: 0px; }*/
	.box-video .video-project .video-slider .item .cover-thumb img {
		width: 55px;
		margin-top: -12px;
	}
	header .wrapper-large ul.menu li.search-btn input[type="search"]:focus,
	header .wrapper-large ul.menu li.search-btn input[type="search"] {
		width: 55vw;
	}
	header.active .wrapper-large ul.menu li.search-btn input[type="search"]:focus,
	header.active .wrapper-large ul.menu li.search-btn input[type="search"] {
		width: 55vw;
	}
	.csr-slider .slick-dots li button {
		bottom: 32px;
		left: 190px;
	}
	.box-video .video-project .item .thumb {
		height: 40%;
	}
	.box-video .video-project {
		height: 500px;
	}
	.box-video .video-project .video-slider .item .cover-thumb {
		top: 17%;
	}
}

@media (max-width: 736px) and (max-height: 520px) {
	.modal .modal-content {
		top: 86px;
	}
	.modal iframe,
	.modal video {
		height: 300px;
	}
}
